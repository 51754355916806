@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

.footer{
    @apply no-underline text-jet hover:text-lightGray
}

.nav-links{
    @apply pl-4 text-xl no-underline text-jet hover:text-lightGray md:text-sm
}

.carousel-mobile-title{
    @apply text-gray-400 px-1 pt-2 relative top-10 md:hidden
}

.carousel-desktop-title{
    @apply hidden md:flex pl-1
}